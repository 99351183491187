import axios, { AxiosError, AxiosResponse } from 'axios'
import { guardia_checkout } from '@/config/endpoints'
import { useMutation , UseMutationOptions } from 'react-query'
import { errorHandler } from '@/config/ErrorBoundary'
import { getToken } from '@/app/services/cookiesServices/getAuthServer'
export interface IMercadopagoId {
	corporate: string,
	deferred: string,
	dependant: boolean,
	dependantUid: string | boolean,
	docId: string,
	providerUid?: string,
	specialty: string,
	uid: string,
}

interface IguardiaData {
	dependant: boolean
	dependantUid: string|false
	deferred: string
	uid: string
}

export interface IMercadopagoIdResult {
	id: string,
	/**
     * @deprecated era para la app nativa
     */
	link: string,
	price: number,
	showFakePrice: boolean,
	initialPrice?: number,
}

type QueryOptions =
	| Omit<
	UseMutationOptions<
	IMercadopagoIdResult,
	AxiosError<unknown>,
	string,
	unknown
	>,
	'mutationFn' | 'mutationKey'
	>
	| undefined;

function useMercadopagoId(
	data: IguardiaData,
	options?: QueryOptions
) {
	return useMutation<IMercadopagoIdResult, AxiosError, string>(
		['Megalith-Query','mercadopagoId', data],
		async (corporate: string) => {
			let firebaseToken: string
			let transactionInfo: AxiosResponse<IMercadopagoIdResult>
			try {
				firebaseToken = await getToken()
			} catch (err) {
				if (errorHandler) errorHandler.report(err as Error)
				throw new Error(`${err}`)
			}

			const headers = { 
				'Authorization': `Bearer ${firebaseToken}`,
				'uid': data.uid, 
				'x-api-key': process.env.NEXT_PUBLIC_UMA_BACKEND_APIKEY 
			}

			try {
				transactionInfo = await axios.post<IMercadopagoIdResult>(
					guardia_checkout,
					{
						...data,
						corporate: corporate,
						country: process.env.NEXT_PUBLIC_COUNTRY,
					},
					{ headers }
				)
			} catch (err) {
				if (errorHandler) errorHandler.report(err as Error)
				throw new Error(`${err}`)
			}

			return transactionInfo.data
		},
		options
	)
}

export default useMercadopagoId
