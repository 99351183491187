import { getFirebaseIdToken } from '@umahealth/fe-firebase'
import axios from 'axios'
import { useMutation } from 'react-query'
import { ICoverageData } from './ICoverageData'
import { UMA_BACKEND_URL } from '@/config/endpoints'
import { revalidateSelectCoverageMedicalGuard } from '@/services/revalidate/medicalGuard'

interface CoverageInfoObject {
	corporate: ICoverageData
	dependantUid?: string,
}

/**
 * @param isDependant si el paciente es un dependant
 * 'Borra' un coverage, en realidad solo pone un flag en flase.
 */
async function changeCoverage(uid: string, dependantUid: string|undefined, coverageInfo: ICoverageData,  ) {
	const firebaseToken = await getFirebaseIdToken()

	const path = dependantUid ? `${UMA_BACKEND_URL}/coverages/dependant/change` : `${UMA_BACKEND_URL}/coverages/change`

	const headers = { 
		'Content-Type': 'Application/Json', 
		'Authorization': `Bearer ${firebaseToken}` ,
		'uid': uid, /** Este siempre es el del usuario principal */
		'x-api-key': process.env.NEXT_PUBLIC_UMA_BACKEND_APIKEY
	}
    
	const bodyRequest = {
		uid: dependantUid?? uid,
		corporate: {
			name: coverageInfo.name,
			affiliate_id: coverageInfo.affiliateId,
			plan: coverageInfo.plan
		}

	}

	await axios.post(path, bodyRequest, { headers })
}

/**
 * Cambia un coverage, incluyendo el nombre.
 * @param isDependant 
 * @returns mutation object
 */
function useChangeCoverage(uid: string) {
	return useMutation({
		mutationKey: ['Megalith-Query', 'deleteCoverage'],
		mutationFn: async (coverageData: CoverageInfoObject) => {
			return changeCoverage(uid, coverageData.dependantUid, coverageData.corporate)
		},
		onSuccess: () => {
			revalidateSelectCoverageMedicalGuard(uid)
		}
	})
}

export default useChangeCoverage    