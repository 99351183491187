import useAddDependant, {IDependantData} from './useAddDependant'
import useSyncDependantsFromCoverage from './useSyncDependantsFromCoverage'
import useRestoreDefaultCoverage from './useRestoreDefaultCoverage'
import useUpdatePatient, {ICorporateInfo} from './useUpdatePatient'
import useUpdateCorporate from './useUpdatePatientCorporate'
import useValidateCoverage from './useValidateCoverage'
import useGetDependant from './useGetDependant'
import useCheckPatientField from './useCheckPatientField'

export {
	useAddDependant,
	useSyncDependantsFromCoverage,
	useRestoreDefaultCoverage,
	useUpdatePatient,
	useUpdateCorporate,
	useValidateCoverage,
	useGetDependant,
	useCheckPatientField,
}

export type {
	IDependantData,
	ICorporateInfo
}